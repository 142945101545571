import React, {Component} from 'react';

import twelve from './DC Logo_Horizontal.png';
import mitsubishi from './ME_Tagline_logo_Make_Yourself_Comfortable.png';

import './Body.css';

class Body extends Component {
    render() {
        return (
            <div className={'body'}>
                <div style={{backgroundColor: 'white', padding: 30}}>
                    <div className={'container'}>
                        <h3>ABOUT US</h3>
                        <p>
                            <a href={'/'}>Lashinsky HVAC</a> is your complete source for residential and
                            commercial heating and air conditioning services! Our HVAC experts will help you decide
                            which of our premium products will best provide you with the luxury-level comfort you
                            deserve.
                        </p>
                        <p>
                            We are proud to partner with top manufacturers like Mitsubishi to bring you the very best in
                            heating & cooling technology. We offer complete installation of ComfortMaker and Mitsubishi
                            products like HVAC systems, furnaces, heat pumps, and more!
                        </p>
                        <p>
                            Should you require service on your current home or business HVAC system, our skilled
                            technicians have the know-how and ability to troubleshoot, adjust or repair your system so
                            that you are comfortable <b>24x7x365</b>.
                        </p>
                        <p>
                            Our responsibility is to ensure that every customer receives fast, high quality, fair priced
                            & reliable service.
                        </p>
                        <h3 style={{marginTop: 30}}>
                            OUR SERVICES:
                        </h3>
                        <h5>Equipment Replacement</h5>
                        <p>
                            We provide the best value on the highest quality comfort systems for your home or business.
                            We will assist you in selecting the perfect system. After all, practically nothing is more
                            important than your comfort and the quality of air that you breathe. We offer a wide range
                            of replacement options. Furnaces, Air Conditioners, Heat Pumps, and Boilers are
                            some the areas we specialize in. Preventative Maintenance – We offer heating and cooling
                            preventative maintenance agreements for homes and businesses of any size to help obtain
                            optimal efficiency and performance from your system for years to come. If you are interested
                            in learning more about preventative maintenance for your heating and cooling system, you
                            should ask one of our representatives about our Energy Savings Agreement for more details.
                        </p>
                        <h5>Service & Repair</h5>
                        <p>
                            Any time of day or night, even on holidays, we can provide emergency HVAC services to your
                            home or place of business, with a minimum of fuss! </p>
                        <h5>Complete System Installation</h5>
                        <p>
                            We specialize in making your home climate comfortable. We will design a custom heating and
                            cooling comfort system that will meet your specific needs. Our dedicated and knowledgeable
                            Comfort Consultants will personally assess your business, design the system, and perform the
                            HVAC installation that is most suitable for you.
                        </p>
                        <h5>Custom Ductwork</h5>
                        <p>
                            With our in-house metal shop, we will design the ideal duct configuration for your specific
                            comfort system. Out metal shop will be able to accommodate the specific needs of any
                            replacement or new installation job we perform.
                        </p>
                        <div style={{marginTop: 30}}>
                            <h5>We are a proud DIAMOND contractor for Mitsubishi cooling and heating</h5>
                            <div>This means we can offer you a 12 year warranty on Mitsubishi installs.</div>
                        </div>
                    </div>
                    <div className={'text-center'}>
                        <img src={twelve} alt={'12 & 12'} style={{width: '15%', padding: 10}}/>
                    </div>
                    <div className={'text-center'}>
                        <img src={mitsubishi} alt={'logos'} style={{width: '30%', padding: 10}}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Body;
