import React, {Component} from 'react';

import './Tail.css';

class Tail extends Component {
    render() {
        return (
            <div className={'tail text-center'}
                 style={{backgroundColor: 'rgba(0, 52, 120, 1)', color: 'white', padding: 30}}>
                <div>
                    LASHINSKY CONTRACTING LLC
                </div>
                <div>
                    LICENSE: PA001255
                </div>
                <div>
                    1033 Broadford Road<br/>
                    Everson, PA 15631
                </div>
                <div>
                    <a style={{color: 'rgba(255,255,255,1)', fontSize: '18px'}} href={'tel:+17249729868'}>
                        <i className='fa fa-phone-square'/> 724.972.9868
                    </a>
                </div>
            </div>
        );
    }
}

export default Tail;
