import React, {Component} from 'react';

import logo from './logo.svg';

import './Logo.css';

class Logo extends Component {
    render() {
        return (
            <div className={'logo'}>
                <img src={logo} alt={'Lashinsky HVAC'}/>
            </div>
        );
    }
}

export default Logo;
