import React, { Component } from 'react';

import Logo from '../logo';

import './Header.css';

class Header extends Component {
    render() {
        return (
            <header className={'header fixed-top'}>
                <nav className='navbar navbar-expand-lg'>
                    <div className='collapse navbar-collapse' style={{ fontSize: '36px' }}>
                        <a className='navbar-brand' href='/' style={{ paddingLeft: 15 }}><Logo/></a>LASHINSKY HVAC
                    </div>
                    <a style={{ color: 'rgba(255,255,255,1)', fontSize: '18px', paddingRight: 15 }}
                       href={'tel:+17249729868'}>
                        <i className='fa fa-phone-square'/> 724.972.9868
                    </a>
                </nav>
            </header>
        );
    }
}

export default Header;
