import React, {Component} from 'react';

import './Lead.css';

class Lead extends Component {
    render() {
        return (
            <div className={'lead'}>
                <div className={'image'}>
                    <div className={'hero'}/>
                    <h2 style={{
                        color: 'white',
                        position: 'absolute',
                        height: 100,
                        zIndex: 30,
                        top: 450,
                        right: 50,
                        textShadow: '2px 2px 4px #000000',
                        textTransform: 'uppercase'
                    }}>Honest, Fair, & Fast Service done right</h2>
                </div>
            </div>
        );
    }
}

export default Lead;
