import React, {Component} from 'react';

import Header from '../header';
import Lead from '../lead'
import Info from '../info';
import Body from '../body';
import Tail from '../tail';

import './App.css';

class App extends Component {
    render() {
        return (
            <div className='app'>
                <Header/>
                <Lead/>
                <Info/>
                <Body/>
                <Tail/>
            </div>
        );
    }
}

export default App;
