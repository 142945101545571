import React, {Component} from 'react';

import repair from './furnace-repair.jpeg';
import ductwork from './custom-ductwork.jpg';
import systemInstall from './roof-top-unit.jpg';
import replaceEquipment from './replace-parts.jpg';

import './Info.css'

class Info extends Component {
    render() {
        return (
            <div className={'info'}>
                <div style={{backgroundColor: '#1f2a2a', padding: 30, color: '#eee', height: 270}}>
                    <div className={'row'}>
                        <div className={'col-md-3 text-center'}>
                            <img src={replaceEquipment} alt={'Complete System Installation'} className={'img-fluid'}
                                 style={{height: 200}}/>
                            <div>Equipment Replacement</div>
                        </div>
                        <div className={'col-md-3 text-center'}>
                            <img src={repair} alt={'Equipment Replacement'} className={'img-fluid'}
                                 style={{height: 200}}/>
                            <div>Service & Repair</div>
                        </div>
                        <div className={'col-md-3 text-center'}>
                            <img src={systemInstall} alt={'Complete System Installation'} className={'img-fluid'}
                                 style={{height: 200}}/>
                            <div>Complete System Installation</div>
                        </div>
                        <div className={'col-md-3 text-center'}>
                            <img src={ductwork} alt={'Equipment Replacement'} className={'img-fluid'}
                                 style={{height: 200}}/>
                            <div>Custom Ductwork</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Info;
